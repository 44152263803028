<template>
  <div class="register">
    <el-form ref="queryParams" :model="queryParams" :rules="loginRules" class="login-form">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item prop="nickName">
            <el-input v-model="queryParams.nickName" placeholder="请输入姓名"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <div style="width: 100%;height: 30px;display: flex;flex-wrap: nowrap;align-items: center">
            <span>设置头像：</span>
            <img :src="userLogo" alt="logo" @click="changeImg" style="display: inline-block;width: 30px;height: 30px;border-radius: 50%">
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="20" style="">
        <el-col :span="12">
          <el-input type="password" v-model="firstPassWord" placeholder="请输入密码" show-password></el-input>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="password">
            <el-input type="password" v-model="queryParams.password" placeholder="确认密码" @change="affirmPass" show-password></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20" style="">
        <el-col :span="12">
          <el-form-item prop="companyName">
<!--            <el-input v-model="queryParams.companyName" placeholder="请输入公司名称"></el-input>-->
            <el-select
                style="width: 100%;"
                v-model="queryParams.companyName"
                :remote-method="remoteMethod"
                clearable
                filterable
                placeholder="请输入公司名称"
                remote
                reserve-keyword
                @change="handleSelect">
              <el-option
                  v-for="item in seachOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.label">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <div style="display: flex;flex-wrap: nowrap;align-items: center;font-size: 14px">
            <p style="padding-top: 8px;"><span>统一社会信用代码：</span><span>{{queryParams.creditCode}}</span></p>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="20" style="">
        <el-col :span="12">
          <el-form-item prop="deptType">
            <el-select v-model="queryParams.deptType" placeholder="请选择部门" style="width: 100%">
              <el-option
                  v-for="item in postOptions"
                  :key="item.dictValue"
                  :label="item.dictLabel"
                  :value="item.dictValue">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="positionType">
            <el-select v-model="queryParams.positionType" placeholder="请选择职务" style="width: 100%">
              <el-option
                  v-for="item in options"
                  :key="item.dictValue"
                  :label="item.dictLabel"
                  :value="item.dictValue">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20" style="">
        <el-col :span="24">
          <el-form-item prop="cityCode">
            <el-cascader
                style="width: 48%"
                :options="provinceAndCityData()"
                v-model="queryParams.cityCode"
                placeholder="所在城市"
                prefix-icon="el-icon-eleme"
                @change="handleCity">
            </el-cascader>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20" style="margin-top: 40px">
        <el-col :span="24" style="text-align: center">
          <el-button type="primary" style="width: 50%;" @click="loginRe">登录</el-button>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
const getUploadBidDocument = (options = {}) => {
  const oNode = document.createElement('input')
  oNode.type = 'file'
  oNode.accept = options.accept || 'image/jpeg,image/jpg,image/png'
  oNode.style.display = 'none'
  oNode.id = 'img' + Date.now()
  const body = document.body
  body.append(oNode)
  setTimeout(() => {
    oNode.click()
    oNode.onchange = event => {
      const files = event.target.files
      if ('change' in options) {
        options.change(files)
      }
      const oInput = document.querySelector('#' + oNode.id)
      body.removeChild(oInput)
    }
  }, 100)
}
import upload from '@/utils/upload'
import {getProduct, userRegister,getPost,oneCompanyInfo,companyName} from "@/utils/api";

import {
  provinceAndCityData,
  pcTextArr,
  regionData,
  pcaTextArr,
  codeToText,
} from "element-china-area-data";

export default {
  name: "register",
  props:{
    phoneNumber:String,
  },
  data() {
    return {
      firstPassWord:'',
      // 信用代码
      // cityCode:[],
      passFlag:0,
      userLogo: require('@/assets/images/user.png'),
      queryParams: {
        nickName: '',
        avatar: '',
        password:'',
        positionType:'',
        companyName:'',
        provinceCode:'',
        cityCode:'',
        deptType:'',
        creditCode:null,
        distributorId:null
      },
      loginRules: {
        nickName: [
          {required: true, trigger: 'blur', message: '请输入您的姓名'}
        ],
        password: [
          {required: true, trigger: 'blur', message: '请输入您的密码'}
        ],
        companyName: [
          {required: true, trigger: 'blur', message: '请输入公司名称'}
        ],
        positionType: [
          {required: true, trigger: 'change', message: '请选择职务',type:'string'}
        ],
        deptType: [
          {required: true, trigger: 'change', message: '请选择部门',type:'string'}
        ],
        cityCode: [
          {required: true, trigger: 'change', message: '请选择城市'}
        ],
      },
      options:[
        {label:'采购经理',value:1},
        {label:'采购员',value:2},
        {label:'技术总工程师',value:3},
        {label:'技术员',value:4},
        {label:'其他',value:0},
      ],
      postOptions:[],
      seachOptions: [],
    }
  },
  mounted(){
    this.getList()
  },
  methods: {
    getList(){
      getPost('sys_customer_dept').then(res=>{
        if(res.data.code === 200){
          console.log(res.data)
          this.postOptions = res.data.data
        }
      })
      getPost('sys_customer_position').then(res=>{
        if(res.data.code === 200){
          this.options = res.data.data
        }
      })
    },
    provinceAndCityData() {
      return provinceAndCityData
    },
    /**
     * Date:2024/03/19
     * Author:wt
     * Function:用户注册信息
     * @param:queryParams
     */
    loginRe(){
      this.$refs.queryParams.validate(valid => {
        if (valid && this.passFlag === 0) {
          this.queryParams.phonenumber = this.phoneNumber
          this.queryParams.userName = this.phoneNumber
          this.queryParams.distributorId = this.$Cookie.get('distributorId')
          userRegister(this.queryParams).then((res)=>{
            if(res.data.code === 200){
              this.$emit('handlePath',1)
            }else{
              this.$message.error(res.data.msg)
              this.loading = false
              return false
            }
          })
        }
      })
    },
    /**
     * Date:2024/3/18
     * Author:wt
     * Function:密码对比确认
     * @param:无
     */
    affirmPass(){
      if(this.firstPassWord === '' || this.firstPassWord !== this.queryParams.password){
        this.$message.error('两次输入密码不一致')
        this.passFlag = 1
      }else if(this.firstPassWord === this.queryParams.password){
        this.passFlag = 0
      }
    },
    handleCity(item){
      this.queryParams.provinceCode = item[0]
      this.queryParams.cityCode = item[1]
      this.queryParams.cityName = codeToText[item[1]]
      this.queryParams.provinceName = codeToText[item[0]]
    },
    /** 系统上传图片 */
    changeImg() {
      // const sysId = this.tableData.systemConfigId
      const vm = this
      getUploadBidDocument({
        change(files) {
          const file = files[0]
          upload(file, {
            resourceType: 1,
            module: 'fs_manage',
            progress(res) {
              // console.log(res,'123123')
            }
          })
              .then(res => {
                vm.userLogo = res.data.resourceUrl
                vm.queryParams.avatar = res.data.resourceId
                // const sysImg= res.data.resourceUrl
                // const sysLogoObject = {
                //   systemLogo: res.data.resourceId,
                //   systemConfigId:sysId
                // }
              })
              .catch(err => {
                console.log(err)
              })
        }
      })
    },
    /**
     * @Date:2024/05/15
     * @function:联想搜索
     * @param query
     */
    remoteMethod(query) {
      if (query !== '') {
        let restaurants = [];
        setTimeout(() => {
          companyName({keyword: query, skip: 0}).then(res => {
            if (res.code === 200) {
              restaurants = this.changeData(res.data.items)
              this.seachOptions = restaurants.filter(item => {
                return item.label.toLowerCase()
                    .indexOf(query.toLowerCase()) > -1;
              });
            }
          })
        }, 200);
      } else {
        this.seachOptions = [];
      }
    },
    /**
     * @Date:2024/05/15
     * @function:选择触发
     * @param item
     */
    handleSelect(item) {
      if (item) {
        oneCompanyInfo({keyword: item}).then(res => {
          if (res.code === 200) {
            this.form.creditCode = res.data.creditNo
          }
        })
      }
    },
  },
  watch:{
    // 'queryParams.password'(newValue,oldValue){
    //   if(this.firstPassWord === newValue){
    //     this.passFlag = 0
    //   }else{
    //     this.passFlag = 1
    //     this.$message.error('两次输入密码不一致')
    //   }
    // },
  }
}
</script>

<style lang="scss" scoped>
.register {
  //::v-deep .el-input--suffix{
  //  width: 100% !important;
  //}
  .login-form {
    background: #ffffff;
    width: 35%;
    height: 500px;
    box-shadow: 0px 0px 6px rgb(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 50px;
    margin: 130px auto;
    //.el-form-item{
    //  margin-bottom: 32px;
    //  .el-form-item__content{
    //    line-height: 36px;
    //  }
    //  .el-input__inner{
    //    height: 36px;
    //  }
    //}
    //.el-input {
    //  height: 36px;
    //  input {
    //    height: 36px;
    //  }
    //}
    //.input-btn{
    //  font-size: 12px;
    //  padding: 0 6px 0 14px;
    //  color: #909399;
    //  display: inline-block;
    //  &.w90{
    //    width: 120px;
    //    text-align: center;
    //  }
    //}
    //.el-select .el-input {
    //  width: 76px;
    //}
    //.input-with-select .el-input-group__prepend {
    //  background-color: #fff;
    //}
  }
}
</style>
