<template>
  <div class="login">
    <div class="fixed-header">
      <nav-bar />
    </div>
    <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form" v-if="logintype==1">
      <h3 class="title">{{ type==1 ? '账号密码登录' : '验证码登录' }}</h3>
      <el-form-item prop="username">
        <el-input v-model="loginForm.username" placeholder="请输入手机号" auto-complete="off" class="input-with-select">
          <!-- <el-select v-model="select" slot="prepend" placeholder="请选择">
            <el-option label="+86" value="1"></el-option>
          </el-select> -->
        </el-input>
      </el-form-item>
      <el-form-item v-if="type === 1" prop="password">
        <el-input v-model="loginForm.password" type="password" auto-complete="off" placeholder="请输入密码"
          @keyup.enter.native="handleLogin">
          <div slot="suffix" @click="forgetPassword">
            <span class="space">|</span>
            <span class="input-btn">忘记密码？</span>
          </div>
        </el-input>
      </el-form-item>

      <el-form-item v-if="type===2" prop="uuid">
        <el-input v-model="loginForm.uuid" placeholder="验证码" @keyup.enter.native="handleLogin">
          <div slot="suffix">
            <span class="space">|</span>
            <span class="input-btn w90" :class="!loginForm.username || flag ? 'disabled' : 'op1'"
              @click="sendCode">{{ buttonmsg }}</span>
          </div>
        </el-input>
      </el-form-item>
      <!-- <el-checkbox v-model="loginForm.rememberMe" style="margin:0px 0px 25px 0px;">记住密码</el-checkbox> -->
      <el-form-item style="width:100%;">
        <el-button :loading="loading" size="medium" type="primary" style="width:100%;"
          @click.native.prevent="handleLogin">
          <span v-if="!loading">登 录</span>
          <span v-else>登 录 中...</span>
        </el-button>
      </el-form-item>
      <div class="change-btn" @click="changeType">{{ type === 2 ? '账号密码登录' : '验证码登录' }}</div>
      <div class="link-box">
        <el-link type="text" class="link" @click="goQuclyrec">快速注册</el-link>
      </div>
    </el-form>
    <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form" v-if="logintype==2">
      <h3 class="title">注 册</h3>
      <el-form-item prop="username">
        <el-input v-model="loginForm.username" placeholder="请输入手机号" auto-complete="off" class="input-with-select">
          <!-- <el-select v-model="select" slot="prepend" placeholder="请选择">
            <el-option label="+86" value="1"></el-option>
          </el-select> -->
        </el-input>
      </el-form-item>

      <el-form-item prop="uuid">
        <el-input v-model="loginForm.uuid" placeholder="验证码" @keyup.enter.native="fastRegister">
          <div slot="suffix">
            <span class="space">|</span>
            <span class="input-btn w90" :class="!loginForm.username || flag ? 'disabled' : 'op1'"
              @click="sendCode">{{ buttonmsg }}</span>
          </div>
        </el-input>
      </el-form-item>
      <!-- <el-checkbox v-model="loginForm.rememberMe" style="margin:0px 0px 25px 0px;">记住密码</el-checkbox> -->
      <el-form-item style="width:100%;">
        <el-button :loading="loading" size="medium" type="primary" style="width:100%;"
          @click.native.prevent="fastRegister">
          <span v-if="!loading">快 速 注 册</span>
          <span v-else>注 册 中...</span>
        </el-button>
      </el-form-item>
      <div class="change-btn">点击“快速注册”，即代表同意《用户服务协议》</div>
      <div class="link-box">
        <el-link type="text" class="link" @click="goLogin">直接登录</el-link>
      </div>
    </el-form>

    <register :phone-number="loginForm.username" v-if="logintype==3" @handlePath="handlePath"></register>
    <forget v-if="logintype==4" @handlePath="handlePath"></forget>
    <div class="bottom">
      <bottom-info :message="{}" :type="true"></bottom-info>
    </div>
    <!--    通用底部-->
  </div>
</template>

<script>

import bottomInfo from "@/views/job/bottomInfo.vue";
// import { getPhoneCode } from '@/api/login'
import Cookies from 'js-cookie'
import { decrypt, encrypt } from '@/utils/jsencrypt'
import NavBar from "@/components/navBar.vue";
import Register from "@/components/register.vue";
import { getCodePass, getPhoneCode, getUserLogin, getUserMessage } from "@/utils/api";
import ForgetPass from "@/components/forgetPass.vue";
import Forget from "@/components/forgetPass.vue";
// import { mapState } from 'vuex'

var timer
export default {
  name: 'Login',
  components: {
    Forget,
    NavBar,
    Register,
    bottomInfo
  },
  data() {
    return {
      type: 2, // 1账号密码登录2验证码登录
      buttonmsg: '获取短信验证码',
      codeFlag: 1,
      ifCode: 0,
      logintype: 1,
      flag: false,
      select: '1',
      codeUrl: '',
      loginForm: {
        username: '',
        password: '',
        rememberMe: false,
        code: '',
        uuid: ''
      },
      loginRules: {
        username: [
          { required: true, trigger: 'blur', message: '请输入您的手机号' }
        ],
        password: [
          { required: true, trigger: 'blur', message: '请输入您的密码' }
        ],
        code: [{ required: true, trigger: 'change', message: '请输入验证码' }]
      },
      loading: false,
      redirect: undefined
    }
  },
  computed: {

    disabled() {
      return !(this.loginForm.phoneNum && this.loginForm.code)
    }
  },
  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect
      },
      immediate: true
    }
  },
  created() {
    if (this.$route.query.from === 'register') {
      this.toPath('2')
      this.codeFlag = 2
    }

    // this.getCookie()
  },
  methods: {
    forgetPassword() {
      this.logintype = 4
    },
    goLogin() {
      this.toPath('1')
      this.codeFlag = 1
      if (timer) clearInterval(timer)
      this.buttonmsg = '获取短信验证码'
    },
    changeType() {
      this.type = this.type === 1 ? 2 : 1
      if (timer) clearInterval(timer)
      this.flag = false
      this.buttonmsg = '获取短信验证码'
    },
    goQuclyrec() {
      this.toPath('2')
      this.codeFlag = 2
      if (timer) clearInterval(timer)
      this.buttonmsg = '获取短信验证码'
      this.flag = false
    },
    sendCode() {
      if (!this.loginForm.username || this.flag) {
        this.$refs.loginForm.validate(valid => {
          if (!valid) return
        })
        return
      }
      // 验证码类型(source来源 1、司机端认证 2、用户实名)
      getPhoneCode({ phone: this.loginForm.username, type: this.codeFlag, distributorId: this.$Cookie.get('distributorId'), }).then(res => {
        if (res.code === 200) {
          this.$message.success('验证码获取成功')
          this.ifCode = 1
          let time = 60
          timer = setInterval(() => {
            time--
            if (time === 0) {
              clearInterval(timer)
              this.flag = false
              this.buttonmsg = '重新获取'
              return
            }
            this.flag = true
            this.buttonmsg = time + 's'
          }, 1000)
        } else {
          if (timer) clearInterval(timer)
        }
      })

    },
    // sendCode(){
    //   this.flag = true
    //   this.buttonmsg = '10s'
    // },
    getCookie() {
      const username = Cookies.get('username')
      const password = Cookies.get('password')
      const rememberMe = Cookies.get('rememberMe')
      this.loginForm = {
        username: username === undefined ? this.loginForm.username : username,
        password: password === undefined ? this.loginForm.password : decrypt(password),
        rememberMe: rememberMe === undefined ? false : Boolean(rememberMe)
      }
    },
    handleLogin() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.loading = true
          // if (this.loginForm.rememberMe) {
          //   Cookies.set("username", this.loginForm.username, { expires: 30 });
          //   Cookies.set("password", encrypt(this.loginForm.password), { expires: 30 });
          //   Cookies.set('rememberMe', this.loginForm.rememberMe, { expires: 30 });
          // } else {
          //   Cookies.remove("username")
          //   Cookies.remove("password")
          //   Cookies.remove('rememberMe')
          // }
          let params = {}
          if (this.type === 1) {
            params = {
              password: this.loginForm.password,
              username: this.loginForm.username,
              distributorId: this.$Cookie.get('distributorId'),
            }
            getUserLogin(params).then(res => {
              if (res.code === 200) {
                const accessToken = res.data.access_token
                this.$Cookie.set('accessToken', accessToken);
                this.$Cookie.set("expiresIn", res.data.expires_in);
                this.$Cookie.set("userName", this.loginForm.username);
                  this.$router.push('/');
               
              } else {

                // this.$message.error(res.msg)
                this.loading = false
                return false
              }
            })
          } else {
            params = {
              phone: this.loginForm.username,
              distributorId: this.$Cookie.get('distributorId'),
              code: this.loginForm.uuid,
            }
            getUserMessage(params).then(res => {
              if (res.code === 200) {
                const accessToken = res.data.access_token
                this.$Cookie.set('accessToken', accessToken);
                this.$Cookie.set("expiresIn", res.data.expires_in);
                this.$Cookie.set("userName", this.loginForm.username);
                  this.$router.push('/');
              
              } else {
                if (timer) clearInterval(timer)
                this.buttonmsg = '获取短信验证码'
                this.$message.error(res.msg)
                this.loading = false
                return false
              }
            })
          }
          params.type = this.type
          // this.$store.dispatch('user/Login', params).then(() => {
          //   this.$router.push({ path: this.redirect || '/' }).catch(() => {})
          // }).catch(() => {
          //   this.loading = false
          // })
        }
      })
    },
    handlePath(item) {
      if (this.type === 2) {
        this.buttonmsg = '获取短信验证码'
      }
      this.toPath(item)
    },
    /**
     * Date:2024/3/18
     * Author:wt
     * Function:快速注册+验证码校验
     * @param:无
     */
    fastRegister() {
      if (this.ifCode === 1 && this.loginForm.uuid !== '') {
        let data = {
          phone: this.loginForm.username,
          code: this.loginForm.uuid,
          distributorId: this.$Cookie.get('distributorId'),
          type: '2'
        }
        getCodePass(data).then((res) => {
          if (res.code === 200) {
            this.toPath('3')
          } else {
            // this.$message.error('验证码错误')
            this.loading = false
          }
        })
      }
    },

    toPath(item) {
      this.logintype = item
    }
  }
}
</script>

<style rel="stylesheet/scss" scoped lang="scss">
.fixed-header {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 12;
  width: 100%;
}
.login {
  background: #f5f7fa;
  height: 100vh;
  overflow: hidden;
}
.title {
  margin: 0px auto 30px auto;
  text-align: center;
  font-size: 20px;
  color: #606266;
}

::v-deep .login-form {
  background: #ffffff;
  width: 370px;
  height: 420px;
  box-shadow: 0px 0px 6px rgb(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 50px;
  margin: 120px auto;
  .el-form-item {
    margin-bottom: 32px;
    .el-form-item__content {
      line-height: 36px;
    }
    .el-input__inner {
      height: 36px;
    }
  }
  .el-input {
    height: 36px;
    input {
      height: 36px;
    }
  }
  .input-btn {
    cursor: pointer;
    font-size: 12px;
    padding: 0 6px 0 14px;
    color: #909399;
    display: inline-block;
    &.w90 {
      width: 120px;
      text-align: center;
    }
  }
  //.el-select .el-input {
  //  width: 76px;
  //}
  .input-with-select .el-input-group__prepend {
    background-color: #fff;
  }
}
.change-btn {
  margin-top: -10px;
  text-align: center;
  font-size: 12px;
  color: #606266;
  cursor: pointer;
}
.link-box {
  border-top: 1px solid #ddd;
  margin: 20px -30px 0;
  padding: 26px 30px;
  text-align: right;
  .link {
    font-size: 12px;
    color: #409eff;
  }
}
.login-tip {
  font-size: 13px;
  text-align: center;
  color: #bfbfbf;
}
.login-code {
  width: 33%;
  height: 38px;
  float: right;
  img {
    cursor: pointer;
    vertical-align: middle;
  }
}

.login-code-img {
  height: 38px;
}
.bottom {
  width: 100%;
  position: absolute;
  bottom: 0;
}
</style>
