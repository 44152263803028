import api from "./request";
// 获取首页信息 path
export const getInfo = (code) => api.get(`/webInfo/getInfo/${code}`)

// 获取注册部门职务信息 path
export const getPost = (code) => api.get(`/sys/dict/data/type/${code}`)
// 获取产品信息
export const getProduct = (data) => api.post(`/product/hot`,data)
// 用户注册
export const userRegister = (data) => api.post(`/customer/login/register`,data)
// 获取验证码
export const getPhoneCode = (data) => api.post(`/customer/login/phone/code`,data)
// 验证码校验
export const getCodePass = (data) => api.post(`/customer/login/verify/code`,data)
// 客户账号登入
export const getUserLogin = (data) => api.post(`/customer/login/account`,data)
// 客户验证码登录
export const getUserMessage = (data) => api.post(`/customer/login/phone`,data)
// 客户重置密码 body
export const getModPass = (data) => api.post(`/customer/login/reset/pwd`,data)
// 获取客户详情  query
export const getUserInfo = (data) =>{
    const queryParams = new URLSearchParams(data).toString();
    return api.post(`/customer/login/info/phone?${queryParams}`)
}
// 查询公司工商信息
export const oneCompanyInfo = (data) =>{
    return api.get( `/customer/queryBusinessInfo?keyword=${data.keyword }`)
}
// 查询公司名称
export const companyName = (data) =>{
    return api.get( `/customer/queryCompanyName?keyword=${data.keyword }&skip=${data.skip}`)
}
// 安全退出
export const userLoginOut = () => api.delete(`/customer/login/logout`)


export const getWebInfoById = (webInfoId) => api.get(`/webInfo/getWebInfoById?webInfoId=${webInfoId }`)







